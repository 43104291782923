<template>
  <div class="AuthorCallback">
    <el-image :src="require('./sunyun.png')" />
    <div>关注隼云公众号</div>
  </div>
</template>

<script>
export default {
  name: "AuthorCallback",
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.AuthorCallback {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}
</style>
