var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "AuthorCallback" },
    [
      _c("el-image", { attrs: { src: require("./sunyun.png") } }),
      _c("div", [_vm._v("关注隼云公众号")]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }